<template>
    <a-modal
        :visible="visible"
        :title="`${!form.id ? 'Tambah' : 'Edit'} - Master Data Salesman`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- no pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="No KTP/NPWP"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="nopeg"
                        has-feedback>
                        <AInput
                            placeholder="no pegawai"
                            :disabled="form.readOnly"
                            v-model:value="form.nopeg" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- nama pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nama Pegawai"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="fullname"
                        has-feedback>
                        <AInput
                            placeholder="nama pegawai"
                            :disabled="form.readOnly"
                            v-model:value="form.fullname" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- username -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Username"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="username"
                        has-feedback>
                        <AInput
                            placeholder="username"
                            :disabled="form.readOnly"
                            v-model:value="form.username" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- email pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Email"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="email"
                        has-feedback>
                        <AInput
                            placeholder="email pegawai"
                            type="email"
                            :disabled="form.readOnly"
                            v-model:value="form.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- kata sandi -->
            <a-row v-if="!form.id" class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Kata Sandi"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="password"
                        has-feedback>
                        <AInputPassword
                            placeholder="kata sandi"
                            :disabled="form.readOnly"
                            v-model:value="form.password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- konfirmasi kata sandi -->
            <a-row v-if="!form.id" class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Konfirmasi Kata Sandi"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="retype_password"
                        has-feedback>
                        <AInputPassword
                            placeholder="konfirmasi kata sandi"
                            :disabled="form.readOnly"
                            v-model:value="form.retype_password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- phone -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nomor Telepon"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="phone"
                        has-feedback>
                        <AInput
                            addon-before="+62"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="^[1-9][0-9]*$"
                            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            placeholder="Ex : 81xxxxxxxxx"
                            v-model:value="form.phone"
                            :disabled="form.readOnly"
                            min="0"
                            maxlength="13" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- distributor -->
            <a-row
                v-if="!form.id && hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Distributor"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="distributor"
                        has-feedback>
                        <FilterDistributor
                            style="width:100%"
                            :disabled="form.readOnly"
                            v-model:value="form.distributor"
                            show-search/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Status"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="active"
                        has-feedback>
                        <ARadioGroup
                            v-model:value="form.active"
                            style="width:100%">
                            <a-radio :value="1">Aktif</a-radio>
                            <a-radio :value="0">Non Aktif</a-radio>
                        </ARadioGroup>
                    </AFormItem>
                </a-col>
            </a-row>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_DISTRIBUTOR,
    ROLE_HELPDESK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterDistributor,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            nopeg: null,
            fullname: null,
            username: null,
            email: null,
            password: null,
            retype_password: null,
            phone: null,
            active: null,
            distributor: [],
            distributors: [],
            readOnly: false,
        }))

        const formRef = ref();

        const state = reactive({
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                nopeg: [
                    {
                        required: true,
                        message: "No KTP/NPWP tidak boleh kosong!",
                    },
                ],
                fullname: [
                    {
                        required: true,
                        message: "Nama pegawai tidak boleh kosong!",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "Username tidak boleh kosong!",
                    },
                    {
                        pattern: /^[a-z][a-z0-9_]*$/,
                        message: "Username harus dimulai dengan huruf dan hanya boleh berisi huruf kecil, angka, atau garis bawah",
                    },
                    {
                        pattern: /^(?!.*__)[a-z0-9_]+$/,
                        message: "Username tidak boleh memiliki garis bawah berturut-turut",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Email tidak boleh kosong!",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Kata sandi tidak boleh kosong!",
                    },
                    {
                        min: 8,
                        message: 'Kata Sandi minimal 8 karakter!',
                    },
                ],
                retype_password: [
                    {
                        required: true,
                        message: "Konfirmasi kata sandi tidak boleh kosong!",
                    },
                    {
                        validator: async (rule, value) => {
                            if (value !== form.password) {
                                return Promise.reject('Konfirmasi kata sandi harus sama dengan kata sandi!')
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: 'Nomor Telepon tidak boleh kosong!',
                    },
                    {
                        max: 12,
                        min: 0,
                        message: 'Maximal nomor telepon 12 angka',
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
                    },
                ],
                distributor: [
                    {
                        required: true,
                        message: "Distributor tidak boleh kosong!",
                    },
                ],
                active: [
                    {
                        required: true,
                        message: "Status tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            form.distributors = []

            form.distributor.forEach(item => {
                form.distributors.push({
                    vendor_id: item,
                })
            })

            if (!form.id) {
                form.post('/api/users-salesman')
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                delete form.password
                delete form.retype_password

                form.put(`/api/users-salesman/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        const fetchByID = (id) => {
            apiClient.get(`/api/users-salesman/${id}`)
                .then(({ data }) => {
                    form.nopeg = data.nopeg
                    form.fullname = data.fullname
                    form.username = data.username
                    form.email = data.email
                    form.phone = data.phone
                    form.active = data.active ? 1 : 0
                })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                fetchByID(props.item.id)
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_DISTRIBUTOR,
            ROLE_HELPDESK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
